import { Circle, Container, H2, Icon, Paragraph, Text, XStack, YStack } from '@holo/ui';
import Image from 'next/image';
import cropPlanningMapMobile from 'public/img/crop-planning-map-mobile.png';
import cropPlanningMap from 'public/img/crop-planning-map.png';

const listItems = ['Crop planning', 'Seeding and field operations', 'Harvesting', 'Inventory and packing', 'Sales'];

const Section2 = () => {
  return (
    <YStack backgroundColor="$contrast" id="workflows">
      <Container>
        <YStack gap="$8" py="$14" $sm={{ flexDirection: 'row' }} $md={{ py: '$30' }}>
          {/* Top (left on desktop) */}
          <YStack $sm={{ flex: 1, flexBasis: 0 }}>
            <H2 color="$base" fontSize="$32" $md={{ fontSize: '$40' }}>
              Fully digitized workflows - <br />
              Reduce labor costs by up to 20%
            </H2>

            <Paragraph lineHeight="$2" color="$basePale" mt="$4">
              By digitizing, integrating, and automating all workflows — from crop planning, greenhouse and field
              operations, to harvesting, inventory, packing, and sales — we have already achieved a 10% reduction in
              labor costs for diversified vegetable producers, and our aim is to help our farmers achieve a 20%
              reduction in labor costs by 2025.
            </Paragraph>

            <YStack space="$4" mt="$8">
              {listItems.map((listItem) => (
                <XStack key={listItem} ai="center" space="$2">
                  <Circle backgroundColor="$accent" width={24} height={24}>
                    <Icon icon="check" size={14} color="$base" />
                  </Circle>
                  <Text fontSize="$14" fontWeight="$semi-bold" color="$base">
                    {listItem}
                  </Text>
                </XStack>
              ))}
            </YStack>
          </YStack>

          {/* Bottom (right on desktop) */}
          <YStack position="relative" justifyContent="center" $sm={{ flex: 1, flexBasis: 0 }}>
            <Image
              src={cropPlanningMap}
              alt="Crop planning map"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Image
              src={cropPlanningMapMobile}
              alt="Crop planning map mobile"
              style={{
                width: '25vw',
                minWidth: 100,
                maxWidth: 178,
                height: 'auto',
                margin: '-30% auto 0 auto',
              }}
            />
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default Section2;
